import React from "react"
import { Link } from "gatsby"
import NavbarLogo from "../images/navbar-logo.png"

type menuItems = {
    name: string
    href: string
}

export default function Navbar() {
    const menus: menuItems[] = [
        {
            name: "achievements",
            href: "/achievements",
        },
        {
            name: "members",
            href: "/members",
        },
        {
            name: "posts",
            href: "/posts",
        },
        {
            name: "getting_started",
            href: "/getting_started",
        },
        {
            name: "about_us",
            href: "/about_us",
        },
    ]

    return (
        <div className="transition text-xl sticky top-0 bg-dark border-b border-gray-400/20 bg-opacity-80 backdrop-filter backdrop-blur-md z-10 font-inconsolata">
            <div className="flex flex-col lg:flex-row items-center py-2 mx-auto px-5 max-w-4xl container">
                <Link to="/" className="group w-32 rounded-full overflow-hidden lg:w-48">
                    <img
                        src={NavbarLogo}
                        className="object-center w-full h-full"
                        alt="CCUG Logo"
                    />
                </Link>

                <div className="no-scrollbar overflow-x-auto lg:block flex flex-row w-full text-gray-new">
                    <div className="flex justify-end items-center">
                        {menus.map((item, index) => {
                            return (
                                <Link
                                    to={item.href}
                                    key={index}
                                    className={`transition duration-200 ease-in-out lg:py-2 block font-medium hover:text-purple ${
                                        index != menus.length - 1
                                            ? "mx-4"
                                            : "ml-4"
                                    }`}
                                    activeClassName="text-purple"
                                >
                                    {item.name}
                                </Link>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
