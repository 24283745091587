import React from "react"
import { AiOutlineYoutube, AiOutlineInstagram } from "react-icons/ai"

export default function Footer() {
    return (
        <footer className="footer font-inconsolata mb-20 mt-10 text-center lg:mb-5 flex justify-between items-center">
            <div>
                <p>
                    Made with
                    <span
                        className="text-red-600 mx-1"
                        role="img"
                        aria-label="heart"
                    >
                        ♥️
                    </span>
                    by CCUG Core Team.
                </p>
            </div>

            <div className="flex gap-2">
                <a
                    href="https://www.instagram.com/cybercom.ug/"
                    target="_blank"
                >
                    <AiOutlineInstagram />
                </a>
                <a
                    href="https://www.youtube.com/@cybercommunityug5296"
                    target="_blank"
                >
                    <AiOutlineYoutube />
                </a>
            </div>
        </footer>
    )
}
