import React, { ReactNode } from "react"
import Footer from "./Footer"
import Navbar from "./Navbar"

export default function DefaultLayout({ children }: { children: ReactNode }) {
    return (
        <>
            <Navbar />
            <div className="container mx-auto max-w-4xl p-5">
                {children}
                <Footer />
            </div>
        </>
    )
}
