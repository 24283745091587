import React from "react"
import type { HeadFC } from "gatsby"
import { DefaultLayout } from "../layouts"

const NotFoundPage = ({ location }: any) => {
    return (
        <DefaultLayout>
            <div className="text-center my-10">
                <h1 className="font-inconsolata text-4xl leading-tight md:leading-normal">
                    404 Not Found
                </h1>
            </div>

            <p className="mb-5 font-inconsolata">
                <code className="text-purple block mb-5">
                    HTTP 1.1 404 NOT FOUND<br/>
                    Location: {location.pathname}
                </code>

                Silakan periksa kembali URL yang ingin kamu akses. 😅🙏
            </p>
        </DefaultLayout>
    )
}

export default NotFoundPage

export const Head: HeadFC = () => <title>Not found</title>
